import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'

function ArtworksMain() {
    const [artworks, setArtworks] = useState([]);

    useEffect(() => {
        axios.get('/artworks.json', { responseType: 'json' })
        .then((response) => {
            const worksGroups = response.data.worksGroups
            // worksGroups.sort((a, b) => {
            //     if (a.title > b.title) return -1;
            //     if (b.title > a.title) return 1;
            //     return 0;
            // })
            setArtworks([...worksGroups]);
        });
    } , []);

    return (
        <div className="main-wrap artworks">
            <Helmet>
                <title>Artworks | INTPCAT</title>
            </Helmet>
            <div className="main-content artworks">
                <div className="content-wrap">
                    <div className="ArtworkHeader">
                        <div className="ArtworkHead-image"><img src="/img/laz_cat.jpg" alt="laz profile"/></div>
                        <div className="ArtworkHead-text">
                            <p>라즈군</p>
                            <span className="description">
                                @lazkun2020<br/>
                                Likes: <br/>#레트로 #일러스트 #하와이안셔츠 
                            </span>
                        </div>
                    </div>
                    <div className="pub-list">
                        {artworks.map((artworkData, i) => {
                            return (
                                <div className="list-item" key={i}>
                                    <Link to={`/artworks/${artworkData.id}`}>
                                        <div className="item-img">
                                            <img alt="" src={`/artworks/${artworkData.works[0].img}`} />
                                        </div>
                                        <div className="item-text hide">
                                            <p>{artworkData.title}</p>
                                        </div>
                                    </Link>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
            <div style={{ height: "100px" }}></div>
        </div>
    )
}

export default ArtworksMain