import React from 'react';
import { Link } from 'react-router-dom';

export class Main extends React.Component {
    constructor(props) {
        super(props);
        this.state = { isMenuOpen: false }
    }
    render() {
        return (
            <div className="main-wrap">
                
                <header className="masthead">
                    <div className="container h-100">
                        <div className="row h-100">
                            <div className="col-lg-6 my-auto">
                                <div className="header-content mx-auto">
                                    <h1 className="mb-5">무심한듯 시크하여 도저히 알수없는 내 고양이의 성격, 웹툰으로 알아보자!</h1>
                                    {/* <a href="#download" className="btn btn-outline btn-xl js-scroll-trigger">INTP Cat 웹툰 보기</a> */}
                                    <Link to="/toons" className="btn btn-outline btn-xl js-scroll-trigger">INTP Cat 웹툰 보기</Link>
                                </div>
                            </div>
                            <div className="col-lg-6 my-auto">
                                <div className="header-image mx-auto">
                                    <img src="img/intpcat_title.png" width="100%" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </header>

                <section className="download bg-primary text-center" id="download">
                    <div className="container">
                    <div className="row">
                        <div className="col-md-8 mx-auto">
                        <h2 className="section-heading">아니 당신, 날 미행한고야? 어떻게 알았지?</h2>
                        <p>내용이 상당히 주관적일 수 있으므로 그점 유의하시기 바랍니다.</p>
                        </div>
                    </div>
                    </div>
                </section>

                <section className="features" id="features">
                    <div className="container">
                    <div className="section-heading text-center">
                        <h2>귀염뽀짝 냥이와 친구들을 만나보세요</h2>
                        <p className="text-muted">저희는 귀하가 무리하게 다가갔다가 냥냥펀치를 쳐맞아 발생할수도 있는 피해는 책임지지 않습니다.</p>
                        <br/>
                        <img src="img/fish_line_ex.png" height="24px" alt="" />
                    </div>
                    <div className="row">
                        <div className="col-lg-4 my-auto">
                        <img src="img/intpcat_main_left.png" width="100%" alt="" style={{marginLeft: "-15px"}} />
                        </div>
                        <div className="col-lg-8 my-auto">
                        <div className="container-fluid">
                            <div className="row">
                            <div className="col-lg-6">
                                <div className="feature-item">
                                <img src="img/profile_intp.png" width="130px" alt="" />
                                <h3>Laz, INTP Cat</h3>
                                <p className="text-muted">혹시 바선생에 관심있니?</p>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="feature-item">
                                <img src="img/profile_intj.png" width="130px" alt="" />
                                <h3>INTJ cat</h3>
                                <p className="text-muted">반짝! 흥미로운데..?</p>
                                </div>
                            </div>
                            </div>
                            <div className="row">
                            <div className="col-lg-6">
                                <div className="feature-item">
                                <img src="img/profile_esfj.png" width="130px" alt="" />
                                <h3>ESFJ cat</h3>
                                <p className="text-muted">우리 오늘부터 절친!</p>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="feature-item">
                                <img src="img/profile_secret.png" width="130px" alt="" />
                                <h3>New Member</h3>
                                <p className="text-muted">비밀에 싸인 신비로운 새 고양이!</p>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </section>

                <section className="cta">
                    <div className="cta-content">
                    <div className="container">
                        <div className="row">
                        <div className="col-lg-8 my-auto">
                            <div className="container-fluid">
                            <div className="row">
                                <div className="col-lg-6">
                                <div className="feature-item">
                                    <img src="img/profile_esfp.png" width="130px" alt=""/>
                                    <h3>ESFP Human</h3>
                                    <p className="text-muted">너는 왜 밖을 안 나가니? 히키코모리니? 그런거니?</p>
                                </div>
                                </div>
                                <div className="col-lg-6">
                                <div className="feature-item">
                                    <img src="img/profile_isfj.png" width="130px" alt=""/>
                                    <h3>ISFJ Human</h3>
                                    <p className="text-muted">아하...하! 우리주인님 어릴때처럼 잡기놀이 해볼까요~!</p>
                                </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6">
                                <div className="feature-item">
                                    <img src="img/profile_infj.png" width="130px" alt=""/>
                                    <h3>INFJ Human</h3>
                                    <p className="text-muted">그때 넘 측은하다고 생각은 했지만, 그래도 이렇게 활발할 줄은 몰랐어ㅠㅠ</p>
                                </div>
                                </div>
                                <div className="col-lg-6">
                                <div className="feature-item">
                                    <img src="img/profile_secret.png" width="130px" alt=""/>
                                    <h3>New Member</h3>
                                    <p className="text-muted">오늘은 누가 어디서 어떤일이?!</p>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div className="col-lg-4 my-auto">
                            <img src="img/intpcat_main.png" width="100%" alt="" style={{marginLeft: "15px"}}/>
                        </div>
                        </div>
                    </div>
                    </div>
                    <div className="overlay"></div>
                </section>

            </div>
        )
    }
}