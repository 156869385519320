import React from 'react';
import './App.css';
import {
  BrowserRouter,
  Outlet,
  Route,
  Routes,
} from "react-router-dom";

import {Menu} from './components/menu';
import {Main} from './pages/main'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import ToonsView from './components/ToonsView';
import ToonsMain from './components/ToonsMain';
import ArtworksMain from './components/ArtworksMain';
import ArtworksView from './components/ArtworksView';
import TestHome from './components/mbtiTest/home/TestHome';
import TestProfile from './components/mbtiTest/countries/TestProfile';
import TestOptions from './components/mbtiTest/options/TestOptions';

function App() {
  return (
    <BrowserRouter>
      <div className="App">

        <Menu />

        <Routes>
          <Route exact path="/" element={<Main />}>
            
          </Route>
          <Route path="/artworks" element={<Outlet />}>
            <Route path="" element={ <ArtworksMain /> }/>
            <Route path=":workID" element={ <ArtworksView /> }/>
          </Route>
          <Route path="/toons" element={<Outlet />}>
            <Route path="" element={ <ToonsMain/> }/>
            <Route path=":toonId" element={ <ToonsView/> }/>
          </Route>
          <Route path="/test" element={<Outlet />}>
            <Route path="" element={ <TestHome/> }/>
            <Route path="go" element={ <TestOptions/> }/>
            <Route path=":mbtiId" element={ <TestProfile/> }/>
          </Route>
        </Routes>

        <footer>
          <div className="footer-content">
            <div className="copyright">@2022 intpcat.net all right reserved.</div>
            <div className="sns-button">
              <a href="https://www.instagram.com/lazkun2020/" rel="noopener noreferrer" target="_blank"><FontAwesomeIcon icon={faInstagram} size="2x" /></a>
            </div>
          </div>
        </footer>
      </div>
    </BrowserRouter>
  );
}

export default App;
