import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import MagicSliderDots from 'react-magic-slider-dots';
import 'react-magic-slider-dots/dist/magic-dots.css';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';


function StytleArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
        className={className}
        style={{ ...style, display: "block", color: "black" }}
        onClick={onClick}
        />
    );
}

function ArtworksView() {
    let { workID } = useParams();
    // console.log({workID});
    const [artworks, setArtworks] = useState([]);

    useEffect(() => {
        axios.get('/artworks.json', { responseType: 'json' })
        .then((response) => {
            const worksGroups = response.data.worksGroups
            setArtworks([...worksGroups]);
        });
    } , []);


    const settings = {
        dots: true,
        arrows: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <StytleArrow />,
        prevArrow: <StytleArrow />,
        appendDots: dots => {
            return <MagicSliderDots dots={dots} numDotsToShow={4} dotWidth={30} />;
        }
    };
    let artworkData = artworks.find(work => work.id === workID);
    if(!artworkData) return (<div>404 not found.</div>)

    window.scrollTo({top: 0, behavior: 'smooth'});

    return (
        <div className="main-wrap artworks">
            <Helmet>
                <title>Artworks - {workID} | INTPCAT</title>
            </Helmet>
            <div className="main-content artworks">
                <div className="content-wrap">

                    <div className="artworks-back-button" style={{ marginBottom: "20px" }}>
                        <Link to="/artworks">
                            <div className="artworks-button"><FontAwesomeIcon icon={faArrowLeft} size="lg" /></div>
                        </Link>
                    </div>

                    <div className="item-view">
                        <div className="works-img-content" style={{ width: artworkData.width }}>
                            <Slider {...settings}>
                                {artworkData.works.map((work, i) => {
                                    return (
                                        <div className="book-img" key={i}>
                                            <img src={`/artworks/${work.img}`} alt={work.title} />
                                        </div>
                                    )
                                })}
                            </Slider>
                        </div>
                        <div className="work-title"><h3>{artworkData.title}</h3></div>
                    </div>
                </div>
            </div>
            <div style={{ height: "100px" }}></div>
        </div>
    )
}

export default ArtworksView