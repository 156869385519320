import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet';
import { Link, useParams } from 'react-router-dom';

function ToonsView() {
    let { toonId } = useParams();
    // console.log({toonId});
    const [toons, setToons] = useState([]);

    useEffect(() => {
        axios.get('/toons.json', { responseType: 'json' })
        .then((response) => {
            const worksGroups = response.data.worksGroups
            setToons([...worksGroups]);
        });
    } , []);

    let toonIndex = 0
    let toonData = toons.find((work, index) => {
        toonIndex = index
        return work.title === unescape(toonId)
    });
    if(!toonData) return (<div>404 not found.</div>)

    window.scrollTo(0, 0);
    
    return (
        <div className="main-wrap toons">
            <Helmet>
                <title>Toons - {toonId} | INTPCAT</title>
            </Helmet>
            <div className="main-content">
                
                <div className="artworks-back-button" style={{ marginBottom: "20px" }}>
                    <Link to="/toons">
                        <div className="artworks-button"><FontAwesomeIcon icon={faArrowLeft} size="lg" /></div>
                    </Link>
                </div>

                <div className="toons-view">
                    {toonData.works.map((work, i) => {
                        return (
                            <div className="works-img-content" key={`toon${toonId}img${i}`}>
                                <div className="work-img"><img src={`/toons/${toonData.src}/${work.img}`} alt={work.title} /></div>
                                {/* <div className="work-title"><h3>{`${work.title}`}</h3></div> */}
                            </div>
                        );
                    })}
                </div>
                <div className="toons-button-menu">
                    {toons[toonIndex + 1] ? (
                        <Link to={`/toons/${toons[toonIndex + 1].title}`}>
                            <div className="toons-button"><span className="btn-text">Prev</span></div>
                        </Link>
                    ) : ""}
                    {toons[toonIndex - 1] ? (
                        <Link to={`/toons/${toons[toonIndex - 1].title}`}>
                            <div className="toons-button"><span className="btn-text">Next</span></div>
                        </Link>
                    ) : ""}
                </div>
            </div>
            <div style={{ height: "100px" }}></div>
        </div>
    )
}

export default ToonsView