import React from 'react';
import { Link } from 'react-router-dom';
import styles from './TestHome.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCopy,
    faArrowAltCircleRight,
} from '@fortawesome/free-solid-svg-icons';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const TestHome = () => {
    const url = window.location.href; // url 복사
    const copyAlert = () => {
        alert('링크 생성!');
    };
    return (
        <>
            <div className={styles.wrapper}>
                <div className={styles.container}>
                    <div className={styles.top}>
                        <img
                            className={styles.logo}
                            src="/img/laz_cat.jpg"
                            alt="로고"
                        />
                    </div>
                    <div className={styles.middle}>
                        <p><b>*** Work in process ***</b> <br/>현재 컨텐츠 내용을 작업중입니다. 완성되지 않은 내용이 있습니다.</p>
                        {/* <h2 className={styles.header}>나는 누구?</h2> */}
                        <p>총 16개의 유형의 MBTI성향을 기반으로<br/>인팁캣 캐릭터 중 당신과 비슷한 캐릭터를 찾아봐요!</p>
                    </div>
                    <div className={styles.bottom}>
                        <Link to="/test/go" className={styles.start__button}>
                            테스트 하기
                            <FontAwesomeIcon
                                icon={faArrowAltCircleRight}
                                className={styles.icon}
                            />
                        </Link>
                        <CopyToClipboard text={url}>
                            <button
                                className={styles.start__button}
                                onClick={copyAlert}
                            >
                                링크복사
                                <FontAwesomeIcon
                                    icon={faCopy}
                                    className={styles.icon}
                                />
                            </button>
                        </CopyToClipboard>
                    </div>
                </div>
            </div>
        </>
    );
};

export default TestHome;
