import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

export class Menu extends React.Component {
    constructor(props) {
        super(props);
        this.state = { isMenuOpen: false }
        this.clickHandler = this.clickHandler.bind(this)
    }

    clickHandler(e) {
        this.setState({ isMenuOpen: !this.state.isMenuOpen });
    }
    render() {
        return <header className="App-header fixed-top">
            <div className="header-wrap">
                <div className="m-menu">
                    <button className="m-menu-toggler" onClick={this.clickHandler}><FontAwesomeIcon icon={faBars} /><span className="sound_only">전체메뉴</span></button>
                </div>
                <div className="header-logo">
                    <a href="/">
                        <h1>
                            {/* INTP CAT<span style={{letterSpacing:'6px'}}> </span> */}
                            <img src="/img/title_wide.png" className="App-logo" alt="logo" />
                        </h1>
                    </a>
                </div>
            </div>
            <nav className={"header-nav " + (this.state.isMenuOpen ? "" : "collapsed")}><ul className="navbar-nav">
                <li className="nav-item">
                    <Link to="/artworks" onClick={this.clickHandler}>ARTWORKS</Link>
                </li>
                <li className="nav-item">
                    <Link to="/toons" onClick={this.clickHandler}>TOONS</Link>
                </li>
                <li className="nav-item">
                    <Link to="/test" onClick={this.clickHandler}>TEST</Link>
                </li>
            </ul></nav>
        </header>
    }
}