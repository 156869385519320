import axios from 'axios';
import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

function ToonsMain() {
    const [toons, setToons] = React.useState([]);

    useEffect(() => {
        axios.get('/toons.json', { responseType: 'json' })
        .then((response) => {
            const worksGroups = response.data.worksGroups
            // worksGroups.sort((a, b) => {
            //     if (a.title > b.title) return -1;
            //     if (b.title > a.title) return 1;
            //     return 0;
            // })
            setToons([...worksGroups]);
        });
    } , []);

  return (
    <div className="main-wrap toons">
        <Helmet>
            <title>Toons | INTPCAT</title>
        </Helmet>
        <div className="main-content">
            <div className="ToonHeader">
                <div className="ToonHead-image"><img src="/toons/intpcat_title.jpg" alt="intp title"/></div>
                <div className="ToonHead-text">
                    <p>고양이 같은 인팁과 친구들의 이야기</p>
                    <span className="description">"인팁아 너는 친구도 없니....? 왜 밖을 안 나가니. 고양이 입구도 뚫어주고 밖에 날씨도 따뜻하고 어떻게 사지 멀쩡한데 그렇게 누워만 있고 하는거도 없는데... 히키코모리니...? 그런거니....?"</span>
                </div>
            </div>
            <div className="ToonList"><div className="ToonList-container"> 
                {toons.map((toonData, i) => {
                    return (
                        <div className="ToonList-element" key={i}>
                            <div className="element-image">
                                <Link to={`/toons/${toonData.title}`}>
                                    <img alt={toonData.works[0].title} src={`/toons/${toonData.src}/${toonData.works[0].img}`}/>
                                </Link>
                            </div>
                            <div className="element-description">
                                <Link to={`/toons/${toonData.title}`}>
                                    <div className="subelement-title">{toonData.title}</div>
                                </Link>
                                <div className="subelement-info">{toonData.works[0].name}</div>
                            </div>
                        </div>
                    );
                })}
            </div></div>
            <div className="ToonFooter"><img src="/toons/toon_end.jpg" alt="toon ends here" /></div>
        </div>
    </div>
  )
}

export default ToonsMain